import GUI from 'lil-gui';
import {
  Clock,
  MeshPhysicalMaterial,
  RepeatWrapping,
  Shader,
  Color,
  DoubleSide,
  Texture,
  Vector3,
  Vector2,
  MultiplyBlending,
  Vector4,
} from 'three';
import ContentProvider from '../../../providers/ContentProvider';
import fragmentShader from './shader.frag';
import vertexShader from './shader.vert';

export default class ChameleonMaterial {
  material?: MeshPhysicalMaterial;
  clock: Clock = new Clock();

  folder?: GUI;

  private _revealCoef = 0;

  private created = false;

  get revealCoef(): number {
    return this._revealCoef;
  }

  set revealCoef(value: number) {
    this._revealCoef = value;
    this.updateCoef();
  }

  constructor() {
    this.material = new MeshPhysicalMaterial({
      fog: true,
      normalMap: ContentProvider.getAsset('skin-normal.webp')!
        .object as Texture,
      // emissiveMap: ContentProvider.getAsset('chameleon-baked-emission.webp')!
      //   .object as Texture,
      // emissive: new Color(0xffffff),
      // emissiveIntensity: 1,
      reflectivity: 0.0,
      color: new Color(0xffffff),
      roughness: 0.787,
      metalness: 0,
      side: DoubleSide,
      opacity: 0,
      transparent: true,
      // transmission: 1.0,
    });
    // this.material.thickness = 0.8;
    const rainbowTexture = ContentProvider.getAsset('rainbow-texture.jpg')!
      .object as Texture;
    const stoneTexture = ContentProvider.getAsset('slag-stone.jpg')!
      .object as Texture;

    rainbowTexture.wrapS = RepeatWrapping;
    rainbowTexture.wrapT = RepeatWrapping;

    stoneTexture.wrapS = RepeatWrapping;
    stoneTexture.wrapT = RepeatWrapping;

    console.log('BUILD!');

    this.material.onBeforeCompile = (shader: Shader) => {
      // console.log(shader.fragmentShader);
      shader.fragmentShader = fragmentShader;
      shader.vertexShader = vertexShader;
      shader.uniforms.image1 = {
        value: rainbowTexture,
      };
      shader.uniforms.image2 = {
        value: stoneTexture,
      };
      shader.uniforms.uvScale = { value: new Vector2(0.15, 0.15) };
      shader.uniforms.strength = { value: 1.0 };
      shader.uniforms.speed = { value: 0.0015 };
      shader.uniforms.time = { value: this.clock.getElapsedTime() };
      shader.uniforms.overrideAmbientColor = { value: new Color(0xffffff) };
      shader.uniforms.overrideAmbientColorIntensity = { value: 2 };
      shader.uniforms.overridePointLightColor = { value: new Color(0xffffff) };
      shader.uniforms.overridePointLightIntensity = { value: 1 };
      shader.uniforms.overridePointLightPosition = {
        value: new Vector3(-1.73, 1.62, -35),
      };
      shader.uniforms.hsb = { value: new Vector4(0.0, 0.5, 0.84, 0.58) };
      shader.uniforms.overridePointLightDistance = { value: -35.0 };
      shader.uniforms.overridePointLightDecay = { value: 0.0 };
      shader.uniforms.chameleonBaseColor = { value: new Color(0x000000) };
      shader.uniforms.chameleonMriIntensity = { value: this._revealCoef };
      this.material!.userData.uniforms = shader.uniforms;

      if (this.created) {
        const l = this.folder!.children.length;
        this.folder!.children[l - 1].destroy();
        this.folder!.children[l - 2].destroy();
        // console.log(this.folder?.children);
      }

      this.created = true;

      const hsbFolder = this.folder!.addFolder('HSB Texture');
      hsbFolder.add(shader.uniforms.hsb.value, 'x', 0, 2, 0.005).name('hue');
      hsbFolder
        .add(shader.uniforms.hsb.value, 'y', 0, 2, 0.005)
        .name('saturation');
      hsbFolder
        .add(shader.uniforms.hsb.value, 'z', 0, 2, 0.005)
        .name('brightness');
      hsbFolder.add(shader.uniforms.hsb.value, 'w', 0, 2, 0.005).name('lerp');
      const mriFolder = this.folder!.addFolder('Colors Effect');
      mriFolder
        .addColor(shader.uniforms.overrideAmbientColor, 'value')
        .name('AmbientLight color');
      mriFolder
        .add(shader.uniforms.overrideAmbientColorIntensity, 'value', 0, 2, 0.01)
        .name('AmbientLight intensity');
      mriFolder
        .addColor(shader.uniforms.overridePointLightColor, 'value')
        .name('PointLight color');
      mriFolder
        .add(shader.uniforms.overridePointLightIntensity, 'value', 0, 3, 0.01)
        .name('PointLight intensity');
      mriFolder.add(
        shader.uniforms.overridePointLightPosition.value,
        'x',
        -10,
        10
      );
      mriFolder.add(
        shader.uniforms.overridePointLightPosition.value,
        'y',
        -10,
        10
      );
      mriFolder.add(
        shader.uniforms.overridePointLightPosition.value,
        'z',
        -50,
        50
      );
      mriFolder
        .add(shader.uniforms.overridePointLightDistance, 'value', -100, 100)
        .name('PointLight distance');
      mriFolder
        .add(shader.uniforms.overridePointLightDecay, 'value', -100, 100)
        .name('PointLight decay');
      mriFolder
        .add(shader.uniforms.speed, 'value', -0.5, 0.5, 0.0001)
        .name('speed');
      mriFolder
        .add(shader.uniforms.strength, 'value', -0.5, 0.5, 0.0001)
        .name('strength');
      mriFolder
        .add(shader.uniforms.uvScale.value, 'x', -5, 5, 0.1)
        .name('uvScaleX');
      mriFolder
        .add(shader.uniforms.uvScale.value, 'y', -5, 5, 0.1)
        .name('uvScaleY');

      mriFolder
        .addColor(shader.uniforms.chameleonBaseColor, 'value')
        .name('Base Color');
      mriFolder
        .add(shader.uniforms.chameleonMriIntensity, 'value', 0, 1, 0.001)
        .name('Mri intensity')
        .listen();
    };
  }

  createControls = (folder: GUI) => {
    this.folder = folder;
  };

  update = () => {
    this.material!.userData.uniforms.time.value = this.clock.getElapsedTime();
  };

  updateCoef = () => {
    this.material!.opacity = 1;
    // const mriIntensity = Math.min(
    //   1,
    //   Math.max(0, (this.revealCoef - 0.9) / 0.1)
    // );
    const mriIntensity = this.revealCoef;

    // if (mriIntensity === 0) {
    //   this.material!.depthTest = false;
    // } else {
    this.material!.depthTest = true;
    // }
    this.material!.userData.uniforms.chameleonMriIntensity.value = mriIntensity;
  };
}
