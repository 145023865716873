import { Camera, Object3D, Vector2, Vector3 } from 'three';

// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export const hexToRgb = (hex: string, byZero = false) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16) / (byZero ? 255 : 1),
        g: parseInt(result[2], 16) / (byZero ? 255 : 1),
        b: parseInt(result[3], 16) / (byZero ? 255 : 1),
      }
    : null;
};

export const hexToInt = (hex: string): number => {
  return parseInt(`0x${hex.replace('#', '').replace('0x', '')}`);
};

/**
 * Converts 3d to 2d (screen postion from -0.5 to 0.5))
 */
export const toScreenPosition = (obj: Object3D, camera: Camera): Vector2 => {
  const vector = new Vector3();
  obj.updateMatrixWorld();
  vector.setFromMatrixPosition(obj.matrixWorld);
  vector.project(camera);

  return new Vector2(vector.x, vector.y);
};

/**
 * Detects an ios device
 */
export const isIOS = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const isFF = () => navigator.userAgent.toLowerCase().includes('firefox');

/**
 * Debounce
 */
export const debounce = (
  n: number,
  fn: (...params: any[]) => any,
  immed = false
) => {
  let timer: number | undefined = undefined;
  return function (this: any, ...args: any[]) {
    if (timer === undefined && immed) {
      fn.apply(this, args);
    }
    clearTimeout(timer);
    timer = window.setTimeout(() => fn.apply(this, args), n);
    return timer;
  };
};

/**
 * randomize value
 */
export const random = (min?: any, max?: any) => {
  if (isArray(min)) return min[~~(Math.random() * min.length)];
  if (!isNumber(max)) (max = min || 1), (min = 0);
  return min + Math.random() * (max - min);
};

export const isArray = (object: any) => {
  return Object.prototype.toString.call(object) == '[object Array]';
};
export const isNumber = (object: any) => {
  return typeof object == 'number';
};
