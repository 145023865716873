import {
  Color,
  DirectionalLight,
  DoubleSide,
  MeshPhysicalMaterial,
  MeshToonMaterial,
  Shader,
  Vector3,
} from 'three';
import useLilGui from '../../../../hooks/useLilGui';
import ContentProvider from '../../../providers/ContentProvider';
import fragShader from './shader.frag';
import vertexShader from './shader.vert';

export default class ForestMaterial {
  // material?: MeshToonMaterial;
  material?: MeshPhysicalMaterial;

  constructor(lightPosition: Vector3) {
    // this.material = new MeshToonMaterial({
    //   color: 0xffffff,
    //   side: DoubleSide,
    // });

    // this.material.onBeforeCompile = (shader: Shader) => {
    //   shader.fragmentShader = fragShader;
    //   shader.vertexShader = vertexShader;
    //   this.material!.userData.uniforms = shader.uniforms;
    //   shader.uniforms.color = { value: new Color(0x262626) };
    //   shader.uniforms.lightPosition = { value: lightPosition };
    //   shader.uniforms.uTime = { value: 0 };

    //   const folder = useLilGui().addFolder('Toon Material');
    //   folder.addColor(shader.uniforms.color, 'value');
    //   const positionFolder = folder.addFolder('Light Position');
    //   positionFolder.add(shader.uniforms.lightPosition.value, 'x', -200, 200);
    //   positionFolder.add(shader.uniforms.lightPosition.value, 'y', -200, 200);
    //   positionFolder.add(shader.uniforms.lightPosition.value, 'z', -200, 200);
    //   folder.close();
    // };

    this.material = new MeshPhysicalMaterial({
      color: 0x1d1b1b,
      side: DoubleSide,
      envMap: ContentProvider.envMap,
      envMapIntensity: 1.723,
      reflectivity: 0.0245,
      roughness: 0.32,
      metalness: 0.062,
    });

    const folder = useLilGui().addFolder('Forest Material');
    folder.addColor(this.material, 'color');
    folder.add(this.material, 'envMapIntensity', 0, 10, 0.001);
    folder.add(this.material, 'reflectivity', 0, 1, 0.001);
    folder.add(this.material, 'roughness', 0, 1, 0.001);
    folder.add(this.material, 'metalness', 0, 1, 0.001);
    folder.close();
  }
}
