import GUI from 'lil-gui';

let lilGui: GUI | null = null;

export default function () {
  if (!lilGui) {
    lilGui = new GUI({});
    document.body.append(lilGui!.domElement);
  }

  return lilGui;
}
