import {
  BoxGeometry,
  BufferGeometry,
  DynamicDrawUsage,
  Group,
  InstancedMesh,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Object3D,
  PerspectiveCamera,
  Raycaster,
  Vector2,
  Vector3,
  Clock,
  DirectionalLight,
} from 'three';
import ContentProvider from '../../providers/ContentProvider';
import ForestMaterial from './forestmaterial/ForestMaterial';
import useLilGui from '../../../hooks/useLilGui';
import {
  computeBoundsTree,
  disposeBoundsTree,
  acceleratedRaycast,
} from 'three-mesh-bvh';

export default class Forest extends Object3D {
  private clock: Clock = new Clock();
  cameraStartPosition?: Object3D;
  cameraEndPosition?: Object3D;
  forestMaterial?: ForestMaterial;
  treeMaterial?: ForestMaterial;
  tree?: Group;

  private _forestLightX = 0;
  private _forestLightY = 0;
  private _forestLightZ = 0;

  private raycaster: Raycaster = new Raycaster();

  set forestLightX(value: number) {
    this._forestLightX = value;
    // this.updateUniforms();
  }

  get forestLightX(): number {
    return this._forestLightX;
  }

  set forestLightY(value: number) {
    this._forestLightY = value;
    // this.updateUniforms();
  }

  get forestLightY(): number {
    return this._forestLightY;
  }

  set forestLightZ(value: number) {
    this._forestLightZ = value;
    // this.updateUniforms();
  }

  get forestLightZ(): number {
    return this._forestLightZ;
  }

  getMouseZPosition = (
    vector: Vector2,
    camera: PerspectiveCamera
  ): { distance: number; point: Vector3 } => {
    this.raycaster.setFromCamera(vector, camera);
    const intersects = this.raycaster.intersectObjects(this.children);
    if (intersects.length > 0) {
      const { distance, point } = intersects[0];
      return { distance, point };
    } else {
      return { distance: -1, point: new Vector3() };
    }
  };

  constructor() {
    super();
    this.raycaster.firstHitOnly = true;
    const geoData = ContentProvider.getAsset('forest-geo.glb')!.object.scene;
    const assetsData: Object3D = ContentProvider.getAsset('forest-assets.gltf')!
      .object.scene as Object3D;
    const ARR_DATA: any = [];
    const dummy = new Object3D();

    BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
    BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
    Mesh.prototype.raycast = acceleratedRaycast;

    this.forestMaterial = new ForestMaterial(new Vector3(200, -21.6, -95));

    geoData.children.forEach((object: Object3D) => {
      const geoId = object.userData.geo;

      if (!geoId) {
        const { name } = object;

        if (name === 'sphere_POS_1') {
          this.cameraStartPosition = new Object3D();
          this.add(this.cameraStartPosition);
          this.cameraStartPosition.position.copy(object.position);
        } else if (name === 'sphere_POS_2') {
          this.cameraEndPosition = new Object3D();
          this.add(this.cameraEndPosition);
          this.cameraEndPosition.position.copy(object.position);
        }
        return;
      }

      if (!ARR_DATA[geoId]) {
        ARR_DATA[geoId] = [];
      }

      const { position, rotation, scale } = object;
      ARR_DATA[geoId].push({ position, rotation, scale });
    });

    for (const geoId in ARR_DATA) {
      const baseGeometry = (assetsData.getObjectByName(geoId) as Mesh)!
        .geometry;
      const instancedMesh = new InstancedMesh(
        baseGeometry,
        this.forestMaterial!.material!,
        ARR_DATA[geoId].length
      );
      // instancedMesh.instanceMatrix.setUsage(DynamicDrawUsage);
      this.add(instancedMesh);

      for (let i = 0; i < ARR_DATA[geoId].length; i++) {
        const source = ARR_DATA[geoId][i];
        dummy.position.set(
          source.position.x,
          source.position.y,
          source.position.z
        );
        dummy.rotation.set(
          source.rotation.x,
          source.rotation.y,
          source.rotation.z
        );
        dummy.scale.set(source.scale.x, source.scale.y, source.scale.z);

        dummy.updateMatrix();

        instancedMesh.setMatrixAt(i, dummy.matrix);
      }

      instancedMesh.updateMatrix();
      instancedMesh.geometry.computeBoundsTree();
    }

    this.addMotherTree();

    this.rotation.y = 2.28;
    this.position.z = -1;
    this.scale.multiplyScalar(10);

    const folder = useLilGui().addFolder('Forest');
    folder.add(this.position, 'x').name('Position x');
    folder.add(this.position, 'y').name('Position y');
    folder.add(this.position, 'z').name('Position z');
    folder.add(this.rotation, 'x').name('Rotation x');
    folder.add(this.rotation, 'y').name('Rotation y');
    folder.add(this.rotation, 'z').name('Rotation z');
    folder.close();

    const light = new DirectionalLight(0xffffff, 1);
    light.position.set(-3, 10, 0);
    this.add(light);
  }

  addMotherTree() {
    this.tree = ContentProvider.getAsset(
      'forest-mother-tree.glb'
    )!.object.scene.children[0];
    this.treeMaterial = new ForestMaterial(new Vector3(64, -20, 116.4));

    (this.tree!.children[0] as Mesh).material = this.treeMaterial.material!;
    (this.tree!.children[1] as Mesh).material = this.treeMaterial.material!;

    this.add(this.tree!);
  }

  hideAll = () => {
    this.children.forEach((mesh: Object3D) => {
      if (mesh !== this.tree) {
        mesh.visible = false;
      }
    });
  };

  showAll = () => {
    this.children.forEach((mesh: Object3D) => {
      mesh.visible = true;
    });
  };

  updateUniforms = () => {
    if (this.forestMaterial!.material!.userData!.uniforms) {
      this.forestMaterial!.material!.userData!.uniforms!.lightPosition.value.x =
        this._forestLightX;
      this.forestMaterial!.material!.userData!.uniforms!.lightPosition.value.y =
        this._forestLightY;
      this.forestMaterial!.material!.userData!.uniforms!.lightPosition.value.z =
        -this._forestLightZ;
    }
  };

  update = () => {
    // this.forestMaterial!.material!.userData!.uniforms!.uTime.value =
    //   this.clock.getElapsedTime();
    // this.treeMaterial!.material!.userData!.uniforms!.uTime.value =
    //   this.clock.getElapsedTime();
  };
}
