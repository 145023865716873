import {
  Clock,
  Color,
  Mesh,
  MeshBasicMaterial,
  PlaneBufferGeometry,
  ShaderMaterial,
  Vector2,
} from 'three';
import vertexShader from './shader.vert';
import fragmentShader from './shader.frag';

export default class Foreground extends Mesh {
  private clock: Clock = new Clock();

  constructor() {
    super();
    this.geometry = new PlaneBufferGeometry(2, 2, 1, 1);
    this.material = new ShaderMaterial({
      vertexShader,
      fragmentShader,
      transparent: true,
      depthTest: false,
      depthWrite: false,
      uniforms: {
        resolution: { value: new Vector2(4, 2) },
        speed: { value: 0 },
        color: { value: new Color(0x000000) },
        backgroundColor: { value: new Color(0xffffff) },
        brightness: { value: 1.5 },
        flowSpeed: { value: new Vector2(0, -0.3) },
        time: { value: this.clock.getElapsedTime() },
        opacity: { value: 1 },
      },
    });

    this.frustumCulled = false;
    this.renderOrder = 8;
  }

  update = () => {
    // (this.material as ShaderMaterial).uniforms.time.value =
    //   this.clock.getElapsedTime();
  };
}
