import SimplexNoise from 'simplex-noise';
import { Color, Group, Vector3 } from 'three';
import Leaf from './Leaf';
import gsap from 'gsap';
import useLilGui from '../../../hooks/useLilGui';
import { random } from '../../core/functions';

export default class Leaves extends Group {
  private simplex = new SimplexNoise();

  private params: any = {
    amount: 5,
    interval: 5,
    boundingBox: new Vector3(80, 30, 30),
    gravity: 2.4,
    wind: 0.0,
  };

  private leaves: Array<Leaf> = [];
  private colors = [
    new Color(0x333336),
    new Color(0x222224),
    new Color(0x333333),
    new Color(0x222222),
  ];

  constructor() {
    super();

    for (let i = 0; i < this.params.amount; i++) {
      const leaf = new Leaf(i, this.params);
      this.leaves.push(leaf);
      this.add(leaf);
    }

    this.reset();

    const gui = useLilGui().addFolder('Leaves');
    gui.add(this.params, 'gravity', 0, 5);
    gui.add(this.params, 'wind', 0, 5);
    gui.close();
    const colors = gui.addFolder('Colors');
    this.colors.forEach((color, index) => {
      colors.addColor(this.colors, index.toString());
    });
  }

  getRandomColor(): Color {
    const color = this.colors[Math.round(random(0, this.colors.length - 1))];
    if (!color) {
      return this.colors[0];
    }
    return this.colors[Math.round(random(0, this.colors.length - 1))];
  }

  drop() {
    let next = 0;

    // find next available
    for (let i = 0; i < this.leaves.length; i++) {
      if (this.leaves[i].isFalling) continue;
      next = i;
      break;
    }

    this.leaves[next].fall(this.getRandomColor());
  }

  reset() {
    for (let i = 0; i < this.leaves.length; i++) {
      const leaf = this.leaves[i];
      gsap.delayedCall(i * this.params.interval, () => {
        leaf.fall(this.getRandomColor());
      });
    }
  }

  animate() {
    const noise = this.simplex.noise2D(Date.now() * 0.00001, 1);
    const wind = Math.abs(noise) * this.params.wind * this.params.wind;

    for (let i = 0; i < this.leaves.length; i++) {
      const leaf = this.leaves[i];

      leaf.update(wind, this.params.gravity);

      // when leaf touches the ground
      if (leaf.position.y < -this.params.boundingBox.y * 0.5) {
        leaf.fall(this.getRandomColor());
      }
    }
  }
}
