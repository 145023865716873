import {
  CanvasTexture,
  FileLoader,
  LinearFilter,
  NearestFilter,
  RGBAFormat,
  Scene,
  sRGBEncoding,
  Texture,
  Mesh,
  MeshStandardMaterial,
  TextureLoader,
  VideoTexture,
  WebGLRenderer,
  Object3D,
  LinearEncoding,
} from 'three';
import { DRACOLoader } from 'three-stdlib/loaders/DRACOLoader';
import { GLTF, GLTFLoader } from 'three-stdlib/loaders/GLTFLoader';
import { getGPUDetails } from '../core/gpuTier';

export type AssetListItem = {
  id: string;
  isLoaded: boolean;
  object: any;
};

export default class ContentProvider {
  /**
   * List of assets (either textures, gltf models, etc)
   */
  static ASSETS: Array<AssetListItem> = [];

  // loader
  static loader: GLTFLoader;
  static textureLoader: TextureLoader;

  static envMap: Texture;

  /**
   * Check if assets are already loaded or not
   * @param assetList array of ids
   * @returns true or false
   */
  static areAssetsLoaded(assetList: Array<string>): boolean {
    let result = true;
    assetList.forEach((asset) => {
      const findElement = this.ASSETS.find((item) => item.id === asset);
      if (!findElement || !findElement.isLoaded) {
        result = false;
      }
    });
    return result;
  }

  /**
   * Disposing assets and clearning memory
   * @param assetList list of either id's to be disposed
   */
  static disposeAssets(assetList: Array<string>): void {
    assetList.forEach((assetId) => {
      const has = ContentProvider.hasAsset(assetId)!;
      if (has) {
        const { object } = ContentProvider.getAsset(assetId, false)!;
        if (object.scene) {
          object.scene.traverse((el: any) => {
            if (el.material) {
              el.material.dispose();
              el.material.map?.dispose();
            }
            if (el.geometry) {
              el.geometry.dispose();
              el = null;
            }
          });
        } else if (object.isTexture) {
          object.dispose();
        }

        const index = this.ASSETS.findIndex((el) => el.id === assetId);
        this.ASSETS.splice(index, 1);
      }
    });
  }

  /**
   * Get an asset
   */
  static getAsset(assetId: string, copy = false): AssetListItem | undefined {
    const item = this.ASSETS.find(
      (value: AssetListItem) => value.id === assetId
    );
    if (!item) {
      return undefined;
    }
    let object = item.object;

    if (copy) {
      if (object.scene) {
        const newScene = (object.scene as Scene).clone();
        object = { scene: newScene };
      } else {
        if (object.clone) {
          object = object.clone();
        }
      }
    }

    return {
      object,
      isLoaded: item.isLoaded,
      id: item.id,
    };
  }

  /**
   * Has asset
   */
  static hasAsset(assetId: string): boolean {
    const item = this.ASSETS.find(
      (value: AssetListItem) => value.id === assetId
    );
    if (!item) {
      return false;
    } else {
      return true;
    }
  }

  /**
   *
   * @param assetList array of assets
   * @returns promise
   */
  static async loadAssets(
    assetList: Array<string>,
    unloadOthers?: boolean
  ): Promise<boolean> {
    // if assets are already loaded, returns true
    if (this.areAssetsLoaded(assetList)) {
      return true;
    }

    if (!this.loader) {
      const draco = new DRACOLoader();
      draco.setWorkerLimit(12);
      draco.setDecoderPath(`${window['webglBaseUrl']}draco/`);

      this.loader = new GLTFLoader();
      this.loader.setDRACOLoader(draco);

      this.textureLoader = new TextureLoader();
    }

    const promises = assetList.map((assetId: string) => {
      let promise;
      if (assetId.match('(gltf|glb)')) {
        promise = this.loader.loadAsync(
          `${window['webglBaseUrl']}models/${assetId}`
        );
        promise
          .then((result: GLTF) => {
            this.setAsset(assetId, result);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (assetId.match('(jpg|png|webp)')) {
        promise = this.textureLoader.loadAsync(
          `${window['webglBaseUrl']}models/textures/${assetId}`
        );
        promise
          .then((result: Texture) => {
            result.encoding = LinearEncoding;
            result.format = RGBAFormat;
            result.minFilter = LinearFilter;
            result.needsUpdate = true;
            result.flipY = false;
            this.setAsset(assetId, result);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (assetId.match('(mp4)')) {
        promise = this.loadVideo(assetId);
        promise
          .then((result) => {
            (result as VideoTexture).encoding = sRGBEncoding;
            (result as VideoTexture).minFilter = LinearFilter;
            (result as VideoTexture).format = RGBAFormat;
            (result as VideoTexture).needsUpdate = true;
            this.setAsset(assetId, result);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      return promise;
    });

    await Promise.all(promises);

    // if not, returns a promise that to be resolved once all assets area loaded
    return true;
  }

  /**
   * Load a video and creates a txture
   */
  static loadVideo(assetId: string): Promise<VideoTexture> {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.style.position = 'absolute';
      video.preload = 'metadata';
      const extension = '.mp4';
      document.body.appendChild(video);

      video.src = `${window['webglBaseUrl']}models/textures/${assetId.replace(
        '.mp4',
        ''
      )}${extension}`;
      video.muted = true;
      video.crossOrigin = '*';
      video.playsInline = true;
      video.autoplay = true;
      video.load();
      video.style.display = 'none';
      const texture = new VideoTexture(video);
      texture.flipY = false;
      texture.minFilter = LinearFilter;
      // texture.format = RGBAFormat;
      texture.encoding = sRGBEncoding;
      texture.needsUpdate = true;
      resolve(texture);
    });
  }

  /**
   * Set an asset loaded elsewhere
   */
  static setAsset(id: string, object: any): void {
    const assetId = id.split('/')[id.split('/').length - 1];
    const item = this.ASSETS.find(
      (value: AssetListItem) => value.id === assetId
    );

    if (item) {
      item.object = object;
    } else {
      this.ASSETS.push({
        id: assetId,
        isLoaded: true,
        object,
      });
    }
  }

  /**
   * Unload assets that are not used
   */
  static unloadOtherAssets(
    assets: Array<string>,
    renderer: WebGLRenderer
  ): void {
    const toUnload = this.ASSETS.filter((el) => {
      return assets.indexOf(el.id) < 0;
    });
    this.disposeAssets(toUnload.map((el) => el.id));
  }
}
