/* eslint-disable @typescript-eslint/ban-ts-comment */
import gsap from 'gsap';
import type { CopyPass } from 'postprocessing';
import {
  BlendFunction,
  ClearMaskPass,
  ClearPass,
  DepthOfFieldEffect,
  EdgeDetectionMode,
  EffectComposer,
  EffectPass,
  GodRaysEffect,
  KernelSize,
  MaskPass,
  NoiseEffect,
  RenderPass,
  SMAAEffect,
  TextureEffect,
  SMAAPreset,
  VignetteEffect,
} from 'postprocessing';
import Stats from 'stats.js';
import type { ShaderMaterial, Texture } from 'three';
import {
  AdditiveBlending,
  AmbientLight,
  Color,
  Fog,
  LinearEncoding,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PerspectiveCamera,
  PlaneBufferGeometry,
  PMREMGenerator,
  PointLight,
  RawShaderMaterial,
  Scene,
  Shader,
  SphereGeometry,
  sRGBEncoding,
  Vector2,
  Vector3,
  VideoTexture,
  WebGLRenderer,
  WebGLRenderTarget,
} from 'three';

import useLilGui from '../hooks/useLilGui';
import '../styles/app.scss';
import Backdrop from './components/backdrop/Backdrop';
import Chameleon from './components/chameleon/Chameleon';
import Cloud from './components/cloud/Cloud';
import Fireflies from './components/fireflies/Fireflies';
import Foreground from './components/foreground/Foreground';
import Forest from './components/forest/Forest';
import { KawaseBlurRadialPass } from './components/kawase-radial-blur/KawaseBlurRadialPass';
import Leaves from './components/leaves/Leaves';
import Lettering from './components/lettering/Lettering';
// import MaskBody from './components/mask-body/MaskBody';
import ContentProvider from './providers/ContentProvider';

/**
 * Main class
 */
export default class ViewPointMedicalWebglApp {
  private camera: PerspectiveCamera | null = null;
  private cameraContainer: Object3D | null = null;
  private renderer: WebGLRenderer | null = null;
  private imageRenderTarget: WebGLRenderTarget | null = null;
  private scene: Scene | null = null;
  private width: number = window.innerWidth;
  private height: number = window.innerHeight;
  private chameleon: Chameleon | null = null;
  private forest: Forest | null = null;
  private leaves: Leaves | null = null;
  private fireflies?: Fireflies;
  private cloud?: Cloud;
  private stats: Stats | null = null;
  private composer: EffectComposer | null = null;
  private enablePostProcessing = true;
  private enableMouseMovement = true;
  private timeline?: gsap.core.Timeline;
  private effects: any = {};

  private maskScene?: Scene;
  private maskCamera?: PerspectiveCamera;
  private maskCircle?: Mesh;
  // private maskBody?: MaskBody;
  private bodyScene?: Scene;
  private bodyCamera?: PerspectiveCamera;
  private bodyPlane?: Mesh;
  private bodyPlaneBlur?: Mesh;
  private videoPlane?: Mesh;

  private lightPoint?: Mesh;

  // private pulse?: Pulse;
  private foreground?: Foreground;

  private isZoomed = false;
  private zoomedCoef = 0;

  private scrollSpeed = 0;
  private enableZoomRender = false;

  private copyPass?: CopyPass;
  private effectPass?: EffectPass;

  FOG_COLOR = new Color(0x000000);
  FOG_NEAR = 0;
  FOG_FAR = 210;

  /**
   * Constructor
   * @param canvas htmlCanvas
   */
  constructor(private canvas: HTMLCanvasElement) {
    canvas.style.position = 'fixed';
    this.stats = new Stats();
    document.body.appendChild(this.stats.dom);
    this.createScene();
    this.startRendering();
    this.startLoading();
    // this.createPostprocessing();
  }

  /**
   * Main strucutral functions
   */
  private createScene(): void {
    this.scene = new Scene();

    this.renderer = new WebGLRenderer({
      canvas: this.canvas!,
      powerPreference: 'high-performance',
      antialias: true,
      alpha: false,
      stencil: false,
      depth: true,
    });
    this.renderer!.outputEncoding = LinearEncoding;
    // this.renderer!.outputEncoding = sRGBEncoding;
    const resolution = { pixelRatio: window.devicePixelRatio };
    useLilGui()
      .add(resolution, 'pixelRatio', 0, 3, 0.001)
      .onChange((value: number) => {
        this.renderer?.setPixelRatio(value);
      });
    // this.renderer.setPixelRatio(0.5);

    this.scene.fog = new Fog(this.FOG_COLOR, this.FOG_NEAR, this.FOG_FAR);
    this.scene.fog.color = this.FOG_COLOR;

    const fog = useLilGui().addFolder('Fog');
    fog
      .addColor(this.scene.fog, 'color')
      .listen()
      .onChange((value: any) => {
        this.renderer?.setClearColor(value);
      });
    fog.add(this.scene.fog, 'near', 0, 3000).listen();
    fog.add(this.scene.fog, 'far', 0, 3000).listen();
    fog.close();

    this.renderer!.setClearColor(new Color(0x000000));

    this.cameraContainer = new Object3D();
    this.scene.add(this.cameraContainer);
    this.camera = new PerspectiveCamera(
      10,
      window.innerWidth / window.innerHeight,
      0.1,
      200
    );
    this.cameraContainer!.position.set(0, 0, 28);
    this.cameraContainer!.add(this.camera);

    const light = new PointLight(0x1111113, 10, 5);
    this.cameraContainer.add(light);
  }

  /**
   * Create postprocessing effects
   */
  private createPostprocessing(): void {
    this.composer = new EffectComposer(this.renderer!, {
      stencilBuffer: true,
      multisampling: 2,
    });
    this.composer.autoRenderToScreen = false;

    const maskPass = new MaskPass(this.maskScene!, this.maskCamera!);

    const bodyRender = new RenderPass(this.bodyScene, this.bodyCamera!);
    bodyRender.enabled = false;

    const clearPass = new ClearPass();
    clearPass.enabled = true;

    this.composer?.addPass(clearPass);

    this.composer?.addPass(maskPass);
    // this.composer?.addPass(new ClearPass(), 0);

    const smaa = new SMAAEffect({
      preset: SMAAPreset.HIGH,
      edgeDetectionMode: EdgeDetectionMode.DEPTH,
    });

    this.composer.addPass(new RenderPass(this.scene!, this.camera!));

    const noise = new NoiseEffect({
      blendFunction: BlendFunction.SCREEN,
      premultiply: true,
    });
    noise.blendMode.opacity.value = 0.45;

    this.effects['noise'] = noise;

    const noiseFolder = useLilGui().addFolder('Noise');
    noiseFolder
      .add(noise.blendMode.opacity, 'value', 0, 1, 0.0001)
      .name('opacity')
      .listen();
    noiseFolder.close();

    this.composer.addPass(new ClearMaskPass());

    const sunMaterial = new MeshBasicMaterial({
      color: 0xbababa,
      transparent: true,
      fog: false,
    });

    const sunGeometry = new SphereGeometry(2, 32, 32);
    const sun = new Mesh(sunGeometry, sunMaterial);
    sun.position.z = -47.68;
    sun.position.x = -8;
    sun.position.y = 4;
    sun.scale.set(2, 1, 1);
    sun.frustumCulled = false;
    sun.matrixAutoUpdate = false;

    const godRays = new GodRaysEffect(this.camera!, sun, {
      height: 240,
      kernelSize: KernelSize.SMALL,
      density: 0.77,
      decay: 0.9,
      weight: 0.3,
      exposure: 0.54,
      samples: 15,
      clampMax: 0.56,
    });

    this.effects['godray'] = godRays;
    godRays.blendMode.opacity.value = 0;

    const folder = useLilGui().addFolder('GodRays');
    folder.addColor(sunMaterial, 'color');
    const updateSun = () => {
      sun.updateMatrixWorld();
      sun.updateMatrix();
    };

    updateSun();
    const position = folder.addFolder('position');
    position.add(sun.position, 'x', -80, 80).onChange(updateSun);
    position.add(sun.position, 'y', -80, 80).onChange(updateSun);
    position.add(sun.position, 'z', -80, 80).onChange(updateSun);
    position.close();
    const scale = folder.addFolder('scale');
    scale.close();
    scale.add(sun.scale, 'x', -80, 80).onChange(updateSun);
    scale.add(sun.scale, 'y', -80, 80).onChange(updateSun);
    scale.add(sun.scale, 'z', -80, 80).onChange(updateSun);
    folder.close();

    const godrayParams = folder.addFolder('parameters');
    const params = {
      resolution: godRays.height,
      blurriness: godRays.blurPass.kernelSize + 1,
      density: godRays.godRaysMaterial.uniforms.density.value,
      decay: godRays.godRaysMaterial.uniforms.decay.value,
      weight: godRays.godRaysMaterial.uniforms.weight.value,
      exposure: godRays.godRaysMaterial.uniforms.exposure.value,
      clampMax: godRays.godRaysMaterial.uniforms.clampMax.value,
      samples: godRays.samples,
      opacity: godRays.blendMode.opacity.value,
      'blend mode': godRays.blendMode.blendFunction,
    };

    godrayParams
      .add(params, 'resolution', [240, 360, 480, 720, 1080])
      .onChange((value: any) => {
        godRays.resolution.height = Number(value);
      });

    godrayParams
      .add(params, 'blurriness', KernelSize.VERY_SMALL, KernelSize.HUGE + 1, 1)
      .onChange((value: any) => {
        godRays.blur = value > 0;
        godRays.blurPass.kernelSize = value - 1;
      });

    godrayParams
      .add(params, 'density', 0.0, 1.0, 0.01)
      .onChange((value: any) => {
        godRays.godRaysMaterial.uniforms.density.value = value;
      });

    godrayParams.add(params, 'decay', 0.0, 1.0, 0.01).onChange((value: any) => {
      godRays.godRaysMaterial.uniforms.decay.value = value;
    });

    godrayParams
      .add(params, 'weight', 0.0, 1.0, 0.01)
      .onChange((value: any) => {
        godRays.godRaysMaterial.uniforms.weight.value = value;
      });

    godrayParams
      .add(params, 'exposure', 0.0, 1.0, 0.01)
      .onChange((value: any) => {
        godRays.godRaysMaterial.uniforms.exposure.value = value;
      });

    godrayParams
      .add(params, 'clampMax', 0.0, 1.0, 0.01)
      .onChange((value: any) => {
        godRays.godRaysMaterial.uniforms.clampMax.value = value;
      });

    godrayParams.add(godRays, 'samples', 15, 200, 1);

    godrayParams
      .add(params, 'opacity', 0.0, 1.0, 0.01)
      .onChange((value: any) => {
        godRays.blendMode.opacity.value = value;
      });

    //@ts-ignore
    const depthOfField = new DepthOfFieldEffect(this.camera!, {
      focusDistance: 0.48,
      focalLength: 0.5,
      bokehScale: 2.0,
      height: 240,
    });
    this.effects['dof'] = depthOfField;
    const dofFolder = useLilGui().addFolder('DepthOfField');
    dofFolder.close();
    dofFolder
      .add(depthOfField.resolution, 'height', [240, 360, 480, 720, 1080])
      .name('resolution')
      .listen();
    dofFolder.add(depthOfField, 'bokehScale', 0, 5.0, 0.001).listen();
    dofFolder
      .add(
        depthOfField.circleOfConfusionMaterial.uniforms.focusDistance,
        'value',
        0.0,
        1.0,
        0.001
      )
      .name('focus Distance')
      .listen();
    dofFolder
      .add(
        depthOfField.circleOfConfusionMaterial.uniforms.focalLength,
        'value',
        0.0,
        1.0,
        0.001
      )
      .name('focus Length')
      .listen();
    dofFolder.add(
      {
        focusOnChameleon: () => {
          const vecChameleon = new Vector3();
          vecChameleon.copy(this.chameleon!.position);
          const vecCamera = new Vector3();
          vecCamera.copy(this.cameraContainer!.position).sub(vecChameleon);
          const distance = depthOfField.calculateFocusDistance(vecCamera);
          depthOfField.circleOfConfusionMaterial.uniforms.focusDistance.value =
            distance;
        },
      },
      'focusOnChameleon'
    );

    const vignette = new VignetteEffect({
      eskil: false,
      offset: -0.29,
      darkness: 1.9,
    });
    this.effects['vignette'] = vignette;
    const vignetteFolder = useLilGui().addFolder('Vignette');
    vignetteFolder
      .add(vignette.uniforms.get('offset')!, 'value', -5, 5, 0.01)
      .name('offset');
    vignetteFolder
      .add(vignette.uniforms.get('darkness')!, 'value', -5, 5, 0.01)
      .name('darkness')
      .listen();
    vignetteFolder.close();

    const effectPass = new EffectPass(
      this.camera!,
      noise,
      smaa,
      depthOfField,
      godRays,
      vignette
    );
    effectPass.renderToScreen = true;
    this.effectPass = effectPass;
    this.composer.addPass(effectPass);

    useLilGui()
      .add(this, 'enablePostProcessing')
      .onChange((value: boolean) => {
        if (value) {
          this.renderer!.outputEncoding = LinearEncoding;
        } else {
          this.renderer!.outputEncoding = sRGBEncoding;
        }
      });
    useLilGui()
      .add(this, 'enableMouseMovement')
      .onChange((value: boolean) => {
        if (!value) {
          this.handleMouseMove();
        }
      });
  }

  /**
   * Starts the render loop and resizes
   */
  private startRendering(): void {
    window.addEventListener('resize', this.handleResizeWindow);
    window.addEventListener('mousemove', this.handleMouseMove);
    gsap.ticker.add(this.render);
    this.handleResizeWindow();
  }

  private startLoading(): void {
    ContentProvider.loadAssets([
      'chameleon.gltf',
      'forest-geo.glb',
      'forest-assets.gltf',
      'forest-mother-tree.glb',
      '2k/rainbow-texture.jpg',
      '2k/slag-stone.jpg',
      '2k/point-light.png',
      '2k/reverse-point-light.png',
      '2k/mask.png',
      '2k/eyes-map.png',
      '2k/eyes-normal.webp',
      '2k/eyes-emissive.png',
      '2k/chameleon-baked-emission.webp',
      '2k/skin-normal.webp',
      '2k/skybox.png',
      '2k/studio.png',
      'scenario/leaf-1.png',
      'scenario/leaf-2.png',
      '2k/cloud.png',
    ])
      .then((result: boolean) => {
        this.createSceneObjects();
        this.createPostprocessing();

        setTimeout(() => {
          this.createTimeline();

          if (window['onWebGLLoaded']) {
            window['onWebGLLoaded']();
          }
        }, 500);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  private createSceneObjects(): void {
    const generator = new PMREMGenerator(this.renderer!);
    generator.compileEquirectangularShader();
    const envMap = generator.fromEquirectangular(
      ContentProvider.getAsset('studio.png')!.object
    );
    ContentProvider.envMap = envMap.texture;

    this.chameleon = new Chameleon();
    this.chameleon.position.set(0, 0, -1);
    this.chameleon.rotation.y = 0.7;
    this.chameleon.scale.set(0.5, 0.5, 0.5);
    this.scene?.add(this.chameleon);

    const texture = ContentProvider.getAsset('skybox.png')!.object as Texture;
    texture.flipY = true;
    texture.needsUpdate = true;
    this.scene!.background = texture;

    this.leaves = new Leaves();
    this.scene?.add(this.leaves);

    this.forest = new Forest();
    this.scene?.add(this.forest);

    // this.camera!.add(light2);

    this.lightPoint = new Mesh(
      new PlaneBufferGeometry(0.6, 0.6, 1, 1),
      new MeshBasicMaterial({
        color: 0xffffff,
        alphaMap: ContentProvider.getAsset('point-light.png')!.object,
        blending: AdditiveBlending,
        opacity: 0.2,
        // alphaTest: 0.9,
        transparent: false,
        depthWrite: false,
        depthTest: false,
      })
    );
    this.lightPoint.position.z = -5;
    this.camera!.add(this.lightPoint);

    this.fireflies = new Fireflies();
    this.scene?.add(this.fireflies);

    this.cloud = new Cloud();
    this.scene?.add(this.cloud);

    window['scene'] = this;

    this.maskScene = new Scene();
    this.maskCircle = new Mesh(
      new PlaneBufferGeometry(9.5, 9.5, 1, 1),
      new MeshBasicMaterial({
        alphaMap: ContentProvider.getAsset('mask.png')!.object,
        color: 0xffffff,
        alphaTest: 0.5,
      })
    );
    this.maskScene.add(this.maskCircle);

    this.maskCamera = new PerspectiveCamera(
      40,
      window.innerWidth / window.innerHeight,
      1,
      1000
    );
    // this.bodyScene?.add(this.maskBody);
    this.maskCamera!.position.set(0, 0, 5);

    this.bodyScene = new Scene();
    this.bodyCamera = new PerspectiveCamera(
      40,
      window.innerWidth / window.innerHeight,
      1,
      1000
    );
    this.bodyCamera!.position.set(0, 0, 5);
    this.imageRenderTarget = new WebGLRenderTarget(
      window.innerWidth,
      window.innerHeight,
      {}
    );

    this.foreground = new Foreground();
    this.scene?.add(this.foreground);

    // this.bodyLettering.visible = false;
    //this.maskBody?.add(this.bodyLettering);

    this.handleResizeWindow();
  }

  /**
   * Render and handlers
   */
  private render = () => {
    this.stats?.begin();

    if (this.composer && this.enablePostProcessing) {
      this.composer.render();
    } else {
      this.renderer?.render(this.scene!, this.camera!);
    }

    if (this.chameleon) {
      this.chameleon.update();
    }

    if (this.forest) {
      this.forest.update();
    }

    if (this.leaves) {
      this.leaves!.animate();
    }

    if (this.fireflies) {
      this.fireflies.update();
    }

    if (this.cloud) {
      this.cloud.update();
    }

    this.stats?.end();
  };

  private handleResizeWindow = () => {
    this.width = window.innerWidth;
    this.height = window.innerHeight;

    this.renderer?.setSize(this.width, this.height, false);
    this.composer?.setSize(this.width, this.height, true);

    this.camera!.aspect = this.width / this.height;
    this.camera?.updateProjectionMatrix();

    if (!this.maskCamera) {
      return;
    }
    this.maskCamera!.aspect = this.width / this.height;
    this.maskCamera?.updateProjectionMatrix();

    this.bodyCamera!.aspect = this.width / this.height;
    this.bodyCamera?.updateProjectionMatrix();
  };

  private handleMouseMove = (event?: MouseEvent) => {
    const mouseVector = new Vector2();

    if (event) {
      mouseVector.x = 2 * (event.clientX / this.width) - 1;
      mouseVector.y = 1 - 2 * (event.clientY / this.height);
    }

    if (window.innerWidth <= 479) {
      mouseVector.x = 0;
      mouseVector.y = 0;
    }

    if (this.enableMouseMovement || !event) {
      gsap.to(this.camera!.position, {
        x: mouseVector.x * (0.4 - this.zoomedCoef * 0.35),
        y: mouseVector.y * (0.4 - this.zoomedCoef * 0.35),
        ease: 'power3.out',
        duration: 3,
        onUpdate: () => {
          // this.camera?.lookAt(0, 0, 0);
        },
      });

      if (this.forest) {
        gsap.to(this.lightPoint!.position, {
          x: mouseVector.x * 0.8,
          y: mouseVector.y * 0.5,
          ease: 'elastic.out',
          duration: 2,
        });
      }
    }

    if (this.chameleon) {
      this.chameleon.lookAtMouse(mouseVector.x, mouseVector.y);
    }
  };

  public setScrollPosition(coef: number) {
    // console.log(coef);
    gsap.to(this.timeline!, {
      progress: coef,
      ease: 'power3.out',
      duration: this.scrollSpeed,
    });
  }

  private createTimeline() {
    const TIMES = {
      fadeIn: 1,
      lightsOn: 1,
      zoomIn: 1,
      zoomOutMask: 1,
      bodyOut: 1,
      chameleonBack: 1,
    };

    //
    this.renderer!.setClearColor(new Color(0xe6e6e6));

    // this.cameraContainer!.position.z = 66.5;
    this.forest!.updateMatrixWorld();
    const worldStartPosition = this.forest!.localToWorld(
      this.forest!.cameraStartPosition!.position
    )!;
    const worldEndPosition = this.forest!.localToWorld(
      this.forest!.cameraEndPosition!.position
    )!;
    // this.fireflies!.visible = false;
    this.cameraContainer!.position.copy(worldStartPosition);

    if (window.innerWidth < 480) {
      worldEndPosition.x -= 0.15;
      worldEndPosition.z += 5;
      worldEndPosition.y += 0.8;
    } else {
      worldEndPosition.z -= 5;
      worldEndPosition.x -= 0.5;
    }

    this.forest!.forestLightZ = worldStartPosition.z * 10.0;

    this.timeline = gsap.timeline({
      paused: true,
      onUpdate: () => {
        const currentLabel = this.timeline!.currentLabel();
        const progress = this.timeline!.progress();
        const isBodyVisible = !(progress < 0.6);

        // const isEnd = progress === 1;
        const isEnd = false;

        this.composer!.passes[2].enabled = !isEnd;
        this.composer!.passes[3].enabled = !isEnd;
        this.composer!.passes[4].enabled = !isEnd;

        this.enableZoomRender = isBodyVisible;

        this.zoomedCoef = 1 - Math.abs(progress - 0.75) / 0.25;

        if (this.timeline!.currentLabel() === 'chameleonBack') {
          this.zoomedCoef = 1;
          this.scene!.fog!.color = new Color(0xe6e6e6);
        } else {
          this.scene!.fog!.color = new Color(0x000000);
        }
      },
    });

    this.timeline.set(
      (this.foreground!.material as ShaderMaterial).uniforms.color,
      {
        value: new Color(0x000000),
      },
      'fadeIn'
    );

    this.timeline.set(
      (this.foreground!.material as ShaderMaterial).uniforms.opacity,
      {
        value: 1,
      },
      'fadeIn'
    );

    this.timeline.to(
      (this.foreground!.material as ShaderMaterial).uniforms.opacity,
      {
        value: 0.0,
        ease: 'linear',
        duration: TIMES.fadeIn,
      },
      'fadeIn'
    );

    this.timeline.to(
      this.effects['vignette'].uniforms.get('darkness'),
      {
        value: 0,
        duration: TIMES.lightsOn,
        ease: 'linear',
      },
      'lightsOn'
    );

    this.timeline.to(
      (this.foreground!.material as ShaderMaterial).uniforms.opacity,
      {
        value: 0.0,
        ease: 'linear',
        duration: TIMES.lightsOn,
      },
      'lightsOn'
    );

    this.timeline.to(
      this.effects['godray'].blendMode.opacity,
      {
        value: 1,
        ease: 'linear',
        duration: TIMES.lightsOn,
      },
      'lightsOn'
    );

    this.timeline.to(
      this.cameraContainer!.position,
      {
        z: worldEndPosition.z,
        x: worldEndPosition.x,
        y: worldEndPosition.y,
        duration: TIMES.zoomIn,
        ease: 'linear',
      },
      'zoomIn'
    );

    this.timeline.to(
      this.chameleon,
      {
        walkProgress: 1,
        duration: TIMES.zoomIn,
        ease: 'linear',
      },
      'zoomIn'
    );

    this.timeline.to(
      this.effects['vignette'].uniforms.get('darkness'),
      {
        value: 0.8,
        duration: TIMES.zoomIn,
        ease: 'linear',
      },
      'zoomIn'
    );

    this.timeline.to(
      this.effects['dof'].circleOfConfusionMaterial.uniforms.focusDistance,
      {
        value: 0.084,
        duration: TIMES.zoomIn,
        ease: 'linear',
      },
      'zoomIn'
    );

    this.timeline.to(
      this.effects['dof'].circleOfConfusionMaterial.uniforms.focalLength,
      {
        value: 0.1,
        duration: TIMES.zoomIn,
        ease: 'linear',
      },
      'zoomIn'
    );

    this.timeline.to(
      this.chameleon!.chameleonMaterial,
      {
        revealCoef: 1,
        duration: TIMES.zoomIn,
        ease: 'linear',
      },
      'zoomIn'
    );

    this.timeline.fromTo(
      this.chameleon!.eyesMaterial,
      { opacity: 0 },
      {
        opacity: 1,
        duration: TIMES.zoomIn,
        ease: 'linear',
      },
      // `zoomIn+=${TIMES.zoomIn * 0.8}`
      `zoomIn`
    );

    this.timeline.to(
      this.forest!,
      {
        forestLightZ: worldEndPosition.z,
        duration: TIMES.zoomIn,
        ease: 'linear',
      },
      'zoomIn'
    );

    this.timeline.to(
      this,
      {
        ease: 'linear',
        duration: TIMES.zoomOutMask * 0.5,
      },
      `zoomOutMask+=${TIMES.zoomOutMask * 0.5}`
    );

    this.timeline.to(
      this,
      {
        ease: 'linear',
        duration: TIMES.zoomOutMask * 0.2,
      },
      `zoomOutMask+=${TIMES.zoomOutMask * 0.8}`
    );
    // this.timeline.to(
    //   this.maskCircle!.scale,
    //   {
    //     x: 0.001,
    //     y: 0.001,
    //     z: 0.001,
    //     ease: 'linear',
    //     duration: TIMES.zoomOutMask * 0.5,
    //   },
    //   'zoomOutMask'
    // );

    this.timeline.to(
      this.maskCircle!.position,
      {
        x: window.innerWidth < 992 ? 0 : 0.5,
        ease: 'linear',
        duration: TIMES.zoomOutMask * 0.5,
      },
      'zoomOutMask'
    );

    this.timeline.to(
      this.effects['godray'].blendMode.opacity,
      {
        value: 0,
        ease: 'linear',
        duration: TIMES.zoomOutMask * 0.5,
      },
      'zoomOutMask'
    );

    this.timeline.to(
      this.effects['vignette']!.uniforms.get('darkness'),
      {
        value: 0.0,
        duration: TIMES.zoomOutMask * 0.5,
        ease: 'linear',
      },
      'zoomOutMask'
    );

    this.timeline.to(
      this.cameraContainer!.position,
      {
        z: worldStartPosition.z * 1.5,
        x: worldEndPosition.x - 2.5,
        ease: 'none',
        duration: TIMES.zoomOutMask * 0.5,
      },
      'zoomOutMask'
    );

    this.timeline.to(
      this.effects['dof'].circleOfConfusionMaterial.uniforms.focalLength,
      {
        value: 0.1,
        duration: TIMES.zoomOutMask * 0.5,
        ease: 'linear',
      },
      'zoomOutMask'
    );

    this.timeline.to(
      this.effects['dof'].circleOfConfusionMaterial.uniforms.focusDistance,
      {
        value: 0.0,
        duration: TIMES.zoomOutMask * 0.5,
        ease: 'linear',
      },
      'zoomOutMask'
    );

    this.timeline.to(
      this.effects['dof'],
      {
        bokehScale: 0.1,
        duration: TIMES.zoomOutMask * 0.1,
        ease: 'linear',
      },
      `zoomOutMask+=${TIMES.zoomOutMask * 0.4}`
    );

    this.timeline.to(
      this.effects['noise'].blendMode.opacity,
      {
        value: 0,
        ease: 'none',
        duration: TIMES.zoomOutMask,
      },
      'zoomOutMask'
    );

    this.timeline.to(
      this.maskCircle!.position,
      {
        y: 10,
        duration: 0,
      },
      'zoomOutMask+=0.4'
    );

    this.timeline.call(this.forest!.showAll, undefined, 'zoomOutMask+=0.8');

    this.timeline.to(
      this,
      {
        ease: 'none',
        duration: TIMES.bodyOut,
      },
      'bodyOut'
    );

    this.timeline.to(
      this.maskCircle!.position,
      {
        y: 0,
        duration: 0,
      },
      'chameleonBack'
    );
    this.timeline.to(
      this.maskCircle!.scale,
      {
        x: 1,
        z: 1,
        y: 1,
        duration: 0,
      },
      'chameleonBack'
    );

    this.timeline.to(
      this.chameleon!.chameleonMaterial,
      {
        revealCoef: 1,
        onStart: () => {
          console.log('here!');
          this.chameleon!.chameleonMaterial!.material!.userData.uniforms.chameleonMriIntensity.value = 1;
          console.log(
            this.chameleon!.chameleonMaterial!.material!.userData.uniforms
              .chameleonMriIntensity.value
          );
        },
        duration: 0.1,
      },
      'chameleonBack'
    );

    this.timeline.set(
      [this.cloud, this.fireflies!, this.leaves!],
      {
        visible: false,
      },
      'chameleonBack'
    );

    this.timeline.call(this.forest!.showAll, undefined, 'chameleonBack');
    this.timeline.call(this.forest!.hideAll, undefined, 'chameleonBack');

    this.timeline.set(
      this.scene!,
      {
        background: undefined,
      },
      'chameleonBack'
    );

    this.timeline.set(
      this.scene!.fog!,
      {
        far: 20,
      },
      'chameleonBack'
    );

    this.timeline.set(
      this.cameraContainer!.position,
      {
        x: -2,
        y: 0.3,
        z: 30,
      },
      'chameleonBack'
    );

    const isMobile = window.innerWidth < 480;

    this.timeline.to(
      this.cameraContainer!.position,
      {
        z: isMobile ? 20 : 12,
        x: isMobile ? 0 : -0.7,
        y: isMobile ? 1 : 0.3,
        duration: TIMES.chameleonBack,
      },
      'chameleonBack'
    );

    this.timeline.to(
      this.scene!.fog!,
      {
        far: 90,
        duration: TIMES.chameleonBack,
      },
      'chameleonBack'
    );
  }
}

window['ViewPointMedicalWebglApp'] = ViewPointMedicalWebglApp;
