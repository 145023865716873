import gsap from 'gsap';
import {
  AnimationAction,
  AnimationClip,
  AnimationMixer,
  Clock,
  Color,
  Mesh,
  MeshPhysicalMaterial,
  MeshStandardMaterial,
  Object3D,
  PointLight,
  SkeletonHelper,
  SkinnedMesh,
  Texture,
} from 'three';
import useLilGui from '../../../hooks/useLilGui';
import ContentProvider from '../../providers/ContentProvider';
import ChameleonMaterial from './chameleon-material/ChameleonMaterial';

export default class Chameleon extends Object3D {
  chameleon: Object3D | null = null;
  eyesMaterial: MeshStandardMaterial | null = null;
  private skinMaterial: MeshPhysicalMaterial | null = null;
  chameleonMaterial: ChameleonMaterial | null = null;
  private mixer: AnimationMixer | null = null;
  private clock: Clock = new Clock();
  private clips: Array<AnimationClip> = [];
  private eyes: Object3D | null = null;
  private actions: Array<AnimationAction> = [];
  private isWalking = false;
  private helper: SkeletonHelper | null = null;
  private eyeLeft: Mesh | null = null;
  private eyeRight: Mesh | null = null;
  private pointLight: PointLight | null = null;

  private bones: Array<string> = [
    'Torso',
    'Spine1',
    'Neck',
    'Head',
    'PointHead',
    'Spine2',
    'ArmHelper_Left',
    'ArmHelper_Right',
    'Hips',
  ];
  private firstUpdate = true;

  private _walkProgress = 0;
  private _hasWalkedIn = false;

  get walkProgress(): number {
    return this._walkProgress;
  }

  set walkProgress(value: number) {
    this._walkProgress = value;

    if (this._walkProgress > 0.9 && !this._hasWalkedIn) {
      this.animateWalkIn();
      this._hasWalkedIn = true;
    }

    if (this._walkProgress === 0) {
      // gsap.killTweensOf(this.chameleon!.position);
      // this.chameleon!.position.x = 1;
      // // this._hasWalkedIn = false;
      // this.stopWalking();
      // this.eyes!.visible = false;
    }
  }

  constructor() {
    super();
    const { object } = ContentProvider.getAsset('chameleon.gltf', false)!;
    this.eyesMaterial = new MeshStandardMaterial({
      map: ContentProvider.getAsset('eyes-map.png')!.object as Texture,
      roughness: 0.5,
      metalness: 0,
      // roughnessMap: ContentProvider.getAsset('eyes-roughness.webp')!
      //   .object as Texture,
      emissiveMap: ContentProvider.getAsset('eyes-emissive.png')!
        .object as Texture,
      emissive: new Color(0xffffff),
      // emissiveIntensity: 0,
      opacity: 0,
      transparent: true,
      color: '#FFFFFF',
      // normalMap: ContentProvider.getAsset('eyes-normal.webp')!
      //   .object as Texture,
    });

    // this.skinMaterial = new MeshPhysicalMaterial({
    //   map: ContentProvider.getAsset('chameleon-baked-body.webp')!
    //     .object as Texture,
    //   normalMap: ContentProvider.getAsset('skin-normal.webp')!
    //     .object as Texture,
    //   emissiveMap: ContentProvider.getAsset('chameleon-baked-emission.webp')!
    //     .object as Texture,
    //   emissive: new Color(0xffffff),
    //   emissiveIntensity: 1,
    //   reflectivity: 0.5,
    //   color: new Color(0xffffff),
    //   roughness: 0.35,
    //   metalness: 0,
    //   attenuationColor: new Color(0xffffff),
    //   attenuationDistance: 0,
    //   clearcoat: 0,
    //   clearcoatRoughness: 0,
    //   ior: 1.3,
    //   side: DoubleSide,
    // });
    this.chameleonMaterial = new ChameleonMaterial();
    this.skinMaterial = this.chameleonMaterial!.material!;

    this.chameleon = object.scene.children[0];
    this.chameleon?.scale.multiplyScalar(20);
    this.chameleon!.rotation.y = Math.PI / 2;
    this.chameleon!.position.set(0, 0, 0);
    this.chameleon!.frustumCulled = false;
    this.chameleon!.traverse((el) => {
      el.frustumCulled = false;
    });

    this.chameleon!.position.x = 1;

    // this.eyeLeft = this.chameleon?.getObjectByName('Eyes_L_2') as Mesh;
    // this.eyeRight = this.chameleon?.getObjectByName('Eyes_R_2') as Mesh;

    // this.eyeLeft!.material = this.eyesMaterial;
    // this.eyeRight!.material = this.eyesMaterial;

    const body = this.chameleon?.getObjectByName(
      'ChameleonBody'
    ) as SkinnedMesh;
    body.material = this.skinMaterial;
    this.add(this.chameleon!);

    this.mixer = new AnimationMixer(this.chameleon!);
    this.clips = object.animations;

    this.helper = new SkeletonHelper(this.chameleon!);
    this.helper!.rotation.y = Math.PI / 2;
    this.helper!.scale.x = -1;

    this.clips.forEach((animation: AnimationClip) => {
      const action = this.mixer!.clipAction(animation)!;
      this.actions.push(action);
      action.weight = 1;
      action.play();
      action.paused = true;
    });

    this.eyes = this.chameleon!.getObjectByName('Eyes')!.clone(true);
    this.eyes.name = 'eyescopy';
    this.chameleon!.getObjectByName('Eyes')!.visible = false;

    this.eyeLeft = this.eyes.getObjectByName('Eyes_L_2') as Mesh;
    this.eyeRight = this.eyes.getObjectByName('Eyes_R_2') as Mesh;

    this.eyeLeft!.material = this.eyesMaterial;
    this.eyeRight!.material = this.eyesMaterial;
    (this.chameleon!.getObjectByName('Eyes_L_2') as Mesh).material =
      this.eyesMaterial;
    (this.chameleon!.getObjectByName('Eyes_R_2') as Mesh).material =
      this.eyesMaterial;

    this.eyes.userData.iniPosition = this.eyes.position.clone();
    this.eyes.userData.iniRotation = this.eyes.rotation.clone();

    this.eyeLeft!.userData.iniPosition = this.eyeLeft!.position.clone();
    this.eyeLeft!.userData.iniRotation = this.eyeLeft!.rotation.clone();

    this.eyeRight!.userData.iniPosition = this.eyeRight!.position.clone();
    this.eyeRight!.userData.iniRotation = this.eyeRight!.rotation.clone();

    const head = this.chameleon!.getObjectByName('Head')!;
    this.eyes.rotation.set(0, 0, 0);
    this.eyes.position.set(0.3, 0.2, 2.1);
    head.add(this.eyes!);

    this.bones.forEach((boneName: string) => {
      const bone = this.chameleon!.getObjectByName(boneName)!;
      bone.userData.iniRotation = bone.rotation.clone();
      bone.userData.iniPosition = bone.position.clone();
    });

    this.pointLight = new PointLight(0xffffff, 1, 0, 2);
    this.pointLight.position.set(0, -3, -1);
    // this.add(this.pointLight);

    const pointLight = new PointLight(0xffffff, 1, 0, 2);
    pointLight.position.set(0, 8, 5);
    // this.add(pointLight);

    // gsap.to(this.pointLight.position, {
    //   x: 3,
    //   y: 0,
    //   ease: 'power4.inOut',
    //   repeat: -1,
    //   duration: 6,
    // });
    // gsap.to(this.pointLight, {
    //   intensity: 5,
    //   distance: 2,
    //   ease: 'power3.in',
    //   repeat: -1,
    //   duration: 3,
    //   yoyo: true,
    // });
    this.createControls();
  }

  createControls() {
    const folder = useLilGui().addFolder('Chameleon');
    folder.addColor(this.eyesMaterial!, 'emissive').name('Eyes');
    folder.addColor(this.skinMaterial!, 'emissive').name('Body Emissive');
    folder.addColor(this.skinMaterial!, 'color').name('Body Color');
    folder.add(this.skinMaterial!, 'roughness', 0, 1, 0.001).name('Roughness');
    folder.add(this.skinMaterial!, 'emissiveIntensity', 0, 1, 0.001);

    folder.add(this.eyes!.rotation, 'x', 0, 1000).name('eye rotation X');
    folder.add(this.eyes!.rotation, 'y', 0, 1000).name('eye rotation Y');
    folder.add(this.eyes!.rotation, 'z', 0, 1000).name('eye rotation Z');

    folder.close();

    this.chameleonMaterial!.createControls(folder);
  }

  animateWalkIn() {
    if (this.isWalking) {
      return;
    }
    this.chameleon!.position.x = 1;
    gsap.to(this.chameleon!.position, {
      x: 0,
      ease: 'linear',
      duration: 2,
      onComplete: () => {
        this.stopWalking();
      },
    });
    this.startWalking();
  }

  updateWalk = () => {
    this.isWalking = true;
    const weight = Math.min(1, Math.max(0, (this.walkProgress - 0.9) / 0.1));
    this.actions.forEach((action: AnimationAction) => {
      action.weight = 1 - weight;
      action.paused = false;
    });
    this.mixer?.setTime(this._walkProgress * 3);
    this.actions.forEach((action: AnimationAction) => {
      action.weight = 1 - weight;
      action.paused = true;
    });
  };

  update() {
    this.mixer?.update(this.clock.getDelta());
    (this.chameleonMaterial as ChameleonMaterial).update();
    if (this.firstUpdate) {
      this.firstUpdate = false;
      //this.stopWalking();

      const head = this.chameleon!.getObjectByName('Head')!;
      this.eyes!.position.set(0, 0, 0);

      head.add(this.eyes!);
      setTimeout(() => {
        this.eyes!.rotation.set(0.1, 0, 1.5);
        this.eyes!.position.set(0.3, 0.2, 2.1);
      }, 2);
    }
  }

  lookAtMouse(coefX: number, coefY: number) {
    if (this.isWalking) {
      return;
    }

    const head = this.chameleon!.getObjectByName('Head')!;
    gsap.to(head.rotation, {
      y: head.userData.iniRotation.y - coefX * 0.2,
      x: head.userData.iniRotation.x + coefY * 0.2,
    });

    const neck = this.chameleon!.getObjectByName('Neck')!;
    gsap.to(neck.rotation, {
      y: neck.userData.iniRotation.y + coefX * 0.1,
      z: neck.userData.iniRotation.z - coefX * 0.1,
      x: neck.userData.iniRotation.x + coefY * 0.1,
    });

    gsap.to(neck.position, {
      x: neck.userData.iniPosition.x - coefX * 0.01,
      y: neck.userData.iniPosition.y + coefY * 0.3,
    });

    const hips = this.chameleon!.getObjectByName('ArmHelper_Left')!;
    // gsap.to(hips.rotation, {
    //   y: hips.userData.iniRotation.y + coefX * 0.1,
    // });
    // gsap.to(hips.position, {
    //   x: hips.userData.iniPosition.x + coefX * 0.01,
    // });

    gsap.to(this.eyeLeft!.rotation, {
      x: 0.9 + coefY,
      y: 0,
      z: coefX * 1,
    });
    gsap.to(this.eyeRight!.rotation, {
      x: Math.PI * 0.9 + coefY * -1,
      y: 0,
      z: coefX,
    });
  }

  stopWalking() {
    if (!this.isWalking) {
      return;
    }
    this.actions.forEach((action, index) => {
      action.paused = true;
      gsap.to(action, {
        weight: 0,
        ease: 'none',
        duration: 0.4,
        onComplete:
          index === 0
            ? () => {
                this.chameleon!.getObjectByName('Eyes')!.visible = false;

                this.eyes!.visible = true;

                this.isWalking = false;
              }
            : undefined,
      });
    });
  }

  startWalking(triggerAnimations = true) {
    if (this.isWalking) {
      return;
    }
    gsap.killTweensOf(this.eyes!.rotation);
    gsap.killTweensOf(this.eyes!.position);

    // const iniRotation = this.eyes!.userData.iniRotation;
    // const iniPosition = this.eyes!.userData.iniPosition;
    // this.eyes!.rotation.copy(iniRotation);
    // this.eyes!.position.copy(iniPosition);
    // this.chameleon!.getObjectByName('Chameleon')!.add(this.eyes!);

    this.chameleon!.getObjectByName('Eyes')!.visible = true;
    this.eyes!.visible = false;

    this.bones.forEach((boneName) => {
      const bone = this.chameleon!.getObjectByName(boneName);
      gsap.killTweensOf(bone!.rotation);
      gsap.killTweensOf(bone!.position);
      gsap.to(bone!.rotation, {
        x: bone!.userData.iniRotation.x,
        y: bone!.userData.iniRotation.y,
        z: bone!.userData.iniRotation.z,
        duration: 0,
      });
      gsap.to(bone!.position, {
        x: bone!.userData.iniPosition.x,
        y: bone!.userData.iniPosition.y,
        z: bone!.userData.iniPosition.z,
        duration: 0,
      });
    });
    this.isWalking = true;
    this.actions.forEach((action) => {
      if (triggerAnimations) {
        action.paused = false;
      }
      gsap.to(action, {
        weight: 1,
        ease: 'none',
        duration: 0,
      });
    });

    // ~ last position in the walk animation
    gsap.to(this.eyeLeft!.rotation, {
      x: 0,
      y: 0,
      z: -0.8,
    });

    // setTimeout(() => {
    //   this.eyes!.visible = true;
    // }, 50);
  }
}
