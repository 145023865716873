import {
  BufferGeometry,
  Clock,
  Color,
  Float32BufferAttribute,
  Points,
  ShaderMaterial,
  Uniform,
} from 'three';

import useLilGui from '../../../hooks/useLilGui';
import fragmentShader from './shader.frag';
import vertexShader from './shader.vert';

export default class Fireflies extends Points {
  private animate = true;

  private clock: Clock = new Clock();
  private uniforms?: any;
  private params = {
    pointCount: 150,
    lifecycle: 6,
    size: 500,
    xMin: -7,
    xMax: 7,
    yMin: -5,
    yMax: 10,
    zMin: -10,
    zMax: 80,
  };

  constructor() {
    super();

    this.init();
    this.createControls();
  }

  init() {
    this.clear();
    this.uniforms = {
      uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
      uTime: { value: 0 },
      uColor: { value: new Color(0xbababa) },
    };
    this.material = new ShaderMaterial({
      uniforms: this.uniforms,
      transparent: true,
      fragmentShader,
      vertexShader,
      alphaTest: 0.01,
    });

    const points: Array<number> = [];
    const sizes: Array<number> = [];
    const displacementX: Array<number> = [];
    const waveXSize: Array<number> = [];
    const waveYSize: Array<number> = [];
    const lifecycles: Array<number> = [];

    for (let i = 0; i < this.params.pointCount; i++) {
      points.push(
        this.params.xMin + Math.random() * (this.params.xMax - this.params.xMin)
      );
      points.push(
        this.params.yMin + Math.random() * (this.params.yMax - this.params.yMin)
      );
      points.push(
        this.params.zMin + Math.random() * (this.params.zMax - this.params.zMin)
      );

      sizes.push(Math.random() * this.params.size);
      displacementX.push(-0.8 + Math.random() * 1.6);
      waveXSize.push(-0.5 + Math.random());
      waveYSize.push(-0.5 + Math.random());

      lifecycles.push(this.params.lifecycle + Math.random());
    }

    this.geometry = new BufferGeometry();
    this.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(points, 3)
    );
    this.geometry.setAttribute('size', new Float32BufferAttribute(sizes, 1));
    this.geometry.setAttribute(
      'displacementX',
      new Float32BufferAttribute(displacementX, 1)
    );
    this.geometry.setAttribute(
      'waveXSize',
      new Float32BufferAttribute(waveXSize, 1)
    );
    this.geometry.setAttribute(
      'waveYSize',
      new Float32BufferAttribute(waveYSize, 1)
    );
    this.geometry.setAttribute(
      'lifecycle',
      new Float32BufferAttribute(lifecycles, 1)
    );
  }

  createControls() {
    const folder = useLilGui().addFolder('Fireflies');
    folder.add(this, 'animate').name('Animate');
    folder.close();
    folder.addColor(this.uniforms.uColor, 'value').name('Color');
    folder
      .add(this.params, 'pointCount')
      .min(1)
      .max(50)
      .step(1)
      .onFinishChange(() => {
        this.init();
      });

    folder
      .add(this.params, 'size')
      .min(1)
      .max(500)
      .step(1)
      .onFinishChange(() => {
        this.init();
      });

    folder
      .add(this.params, 'lifecycle')
      .min(1)
      .max(20)
      .step(1)
      .onFinishChange(() => {
        this.init();
      });

    folder
      .add(this.params, 'xMin')
      .min(-20)
      .max(20)
      .step(1)
      .onFinishChange(() => {
        this.init();
      })
      .name('Pos X min');

    folder
      .add(this.params, 'xMax')
      .min(-20)
      .max(20)
      .step(1)
      .onFinishChange(() => {
        this.init();
      })
      .name('Pos X max');

    folder
      .add(this.params, 'yMin')
      .min(-20)
      .max(20)
      .step(1)
      .onFinishChange(() => {
        this.init();
      })
      .name('Pos Y min');

    folder
      .add(this.params, 'yMax')
      .min(-20)
      .max(20)
      .step(1)
      .onFinishChange(() => {
        this.init();
      })
      .name('Pos Y max');

    folder
      .add(this.params, 'zMin')
      .min(-100)
      .max(200)
      .step(1)
      .onFinishChange(() => {
        this.init();
      })
      .name('Pos Z min');

    folder
      .add(this.params, 'zMax')
      .min(-100)
      .max(200)
      .step(1)
      .onFinishChange(() => {
        this.init();
      })
      .name('Pos z max');
  }

  update = () => {
    if (!this.animate) return;

    (this.material as ShaderMaterial).uniforms.uTime.value =
      this.clock.getElapsedTime();
  };
}
