import {
  MeshLambertMaterial,
  Mesh,
  Object3D,
  PlaneBufferGeometry,
  Texture,
  Clock,
} from 'three';
import ContentProvider from '../../providers/ContentProvider';
import useLilGui from '../../../hooks/useLilGui';

export default class Cloud extends Object3D {
  private clock: Clock = new Clock();
  clouds: Mesh[] = [];
  params = {
    opacity: 0.15,
    color: 0x696363,
    count: 80,
    size: 6,
    zMin: -100,
    zMax: 100,
  };

  constructor() {
    super();

    this.create();
    this.createControls();
  }

  refresh() {
    this.clouds = [];
    this.clear();
    this.create();
  }

  create() {
    const texture = ContentProvider.getAsset('cloud.png')!.object as Texture;
    const geometry = new PlaneBufferGeometry(
      this.params.size,
      this.params.size
    );

    const material = new MeshLambertMaterial({
      map: texture,
      emissive: this.params.color,
      opacity: this.params.opacity,
      transparent: true,
      depthWrite: false,
      // depthTest: false,
    });

    for (let i = 0; i < this.params.count; i++) {
      const mesh = new Mesh(geometry, material);

      mesh.position.set(
        Math.random() * 10 - 5,
        -2 - Math.random() * 5,
        this.params.zMin + Math.random() * (this.params.zMax - this.params.zMin)
      );
      mesh.rotation.z = Math.random() * 360;

      this.clouds.push(mesh);
      this.add(mesh);
    }
  }

  createControls() {
    const folder = useLilGui().addFolder('Clouds');

    folder
      .add(this.params, 'size')
      .min(1)
      .max(500)
      .step(1)
      .onFinishChange(() => {
        this.refresh();
      });

    folder
      .add(this.params, 'count')
      .min(1)
      .max(500)
      .step(1)
      .onFinishChange(() => {
        this.refresh();
      });

    folder.addColor(this.params, 'color').onFinishChange(() => {
      this.refresh();
    });

    folder
      .add(this.params, 'opacity')
      .min(0)
      .max(1)
      .step(0.01)
      .onFinishChange(() => {
        this.refresh();
      });

    folder
      .add(this.params, 'zMin')
      .min(-1000)
      .max(1000)
      .step(0.1)
      .onFinishChange(() => {
        this.refresh();
      });

    folder
      .add(this.params, 'zMax')
      .min(-1000)
      .max(1000)
      .step(0.1)
      .onFinishChange(() => {
        this.refresh();
      });

    folder.close();
  }

  update() {
    for (let i = 0; i < this.clouds.length; i++) {
      this.clouds[i].rotation.z += this.clock.getDelta() * 0.12;
    }
  }
}
