import {
  BufferAttribute,
  BufferGeometry,
  Color,
  DoubleSide,
  Mesh,
  MeshBasicMaterial,
  Texture,
  Vector3,
} from 'three';
import { random } from '../../core/functions';
import SimplexNoise from 'simplex-noise';
import ContentProvider from '../../providers/ContentProvider';
import gsap from 'gsap';
import { showHidden } from 'yargs';

/**
 * PORT FROM  https://codepen.io/brunoimbrizi/pen/apLERO
 */
export default class Leaf extends Mesh {
  private angle: Vector3 | null = null;
  private radius: Vector3 | null = null;
  private pos: Vector3 | null = null;
  private mass: number = random(0.8, 1.0);
  private value = 0;
  private leafScale: number = random(1, 1.5);
  private simplex = new SimplexNoise();
  public isFalling = false;

  constructor(
    private index: number,
    private params: {
      boundingBox: { x: number; y: number; z: number };
    }
  ) {
    super();

    this.angle = new Vector3(0, random(), random());
    this.radius = new Vector3(
      random(20),
      random(),
      random(params.boundingBox!.x * 0.5)
    );
    this.pos = new Vector3(
      (Math.random() < 0.5 ? -5 : 5) + Math.random() * 2,
      params.boundingBox.y * 0.5 + random(params.boundingBox.y * 0.05),
      0
    );

    this.initMesh();
  }

  initMesh() {
    this.geometry = new BufferGeometry();

    // positions
    const positions = new BufferAttribute(new Float32Array(4 * 3), 3);
    const bend = 0.25;
    positions.setXYZ(0, -0.5, 0.5, bend);
    positions.setXYZ(1, 0.5, 0.5, 0.0);
    positions.setXYZ(2, -0.5, -0.5, 0.0);
    positions.setXYZ(3, 0.5, -0.5, bend);
    this.geometry.setAttribute('position', positions);

    // uvs
    const uvs: BufferAttribute = new BufferAttribute(
      new Float32Array(4 * 2),
      2
    );
    uvs.setXYZ(0, 0, 0, 0);
    uvs.setXYZ(1, 1.0, 0, 0);
    uvs.setXYZ(2, 0, 1.0, 0);
    uvs.setXYZ(3, 1, 1.0, 0);
    this.geometry.setAttribute('uv', uvs);

    // index
    this.geometry.setIndex(
      new BufferAttribute(new Uint16Array([0, 1, 2, 2, 1, 3]), 1)
    );

    this.material = new MeshBasicMaterial({
      fog: false,
    });
    const material = this.material as MeshBasicMaterial;
    material.map = ContentProvider.getAsset(
      `leaf-${Math.random() < 0.5 ? 1 : 2}.png`
    )?.object as Texture;
    material.side = DoubleSide;
    material.transparent = true;
    material.depthTest = false;
    material.fog = true;
    // material.alphaTest = 0.5;
    this.visible = false;
    this.scale.set(this.leafScale, this.leafScale, this.leafScale);
  }

  fall = (color: Color) => {
    this.pos = new Vector3(
      (Math.random() < 0.5 ? -5 : 5) + Math.random() * 1,
      this.params.boundingBox.y,
      0
    );

    const material = this.material as MeshBasicMaterial;
    material.color = color;
    this.visible = true;
    this.position.y = this.pos.y;
    this.isFalling = true;
  };

  hide = () => {
    this.visible = false;
    this.isFalling = false;
    gsap.killTweensOf(this);
  };

  rest = () => {
    this.isFalling = false;
    gsap.killTweensOf(this);
    this.rotation.x = this.rotation.x % Math.PI;
    gsap.to(this.rotation, { duration: 0.3, x: Math.PI / 2, y: 0 });
  };

  update = (wind = 0, gravity = 1) => {
    if (!this.isFalling) return;

    const noise = this.simplex.noise2D(Date.now() * 0.0002, this.index);
    this.value = noise * 2;

    this.angle!.x += 0.01 / Math.max(noise, 0.05);
    this.angle!.y += noise;
    this.angle!.z += noise * 0.05;

    this.pos!.x += wind;
    //   // this.pos.y += (1 - this.value) * this.radius.y * this.gravity * -0.1 - this.mass;
    //   // this.pos.y += (2 - abs(this.value)) * gravity * -this.mass;
    this.pos!.y -= (gravity - this.value) * 0.08;

    this.position.x = this.pos!.x + this.value * 0.5 * this.radius!.x;
    this.position.y = Math.cos((this.value * Math.PI) / 2) * -this.radius!.y;
    // this.position.z = Math.sin(this.angle!.z) * this.radius!.z;

    this.position.x += this.pos!.x;
    this.position.y += this.pos!.y;

    this.rotation.x = this.angle!.x;
    //   // this.mesh.rotation.x = HALF_PI + sin(this.value * HALF_PI);
    //   // this.mesh.rotation.y = PI + this.angle.y;
    this.rotation.z = this.angle!.z;
  };
}
